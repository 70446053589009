import styled from "styled-components";
import media from 'styled-media-query';
import { Link } from "gatsby"

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin: 0 7.22vw;

  ${media.lessThan('medium')`
    margin: 0 16px;  
    height: 80px;      
  `}
`;

const CloseButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 50px;
  background: #E9F2FB;
  border-radius: 80px;
  font-size: 16px;
  line-height: 160%;
  color: #273FD7;
  cursor: pointer;
  text-decoration: none;

  svg {
    margin-right: 14.9px;
  }
`;

const Content = styled.div`
  display: grid;
  align-content: center;
  margin-top: -120px;
  padding: 0 8px;
  ${media.lessThan('medium')`
    margin-top: -80px;
  `}

  h1 {
    font-weight: 500;
    font-size: 72px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #273FD7;
    max-width: 500px;
    margin: 0 auto;

    ${media.lessThan('medium')`
      font-weight: 600;
      font-size: 42px;
      line-height: 130%;  
    `}
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.04em;
    color: #1A1B23;
    margin: 24px auto 16px auto;
    max-width: 500px;
    text-align: center;
    ${media.lessThan('medium')`
      margin-top: 16px;
    `}
  }
`;

export {
  Container,
  Header,
  CloseButton,
  Content
}