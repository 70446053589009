import React from 'react'
import SVG from 'react-inlinesvg';
import {
  Container,
  Header,
  CloseButton,
  Content
} from "./styled"

const Thank = () => (
  <Container>
    <Header>
      <SVG src={'/img/marca.svg'}/>
      <CloseButton to="/">
        <SVG src={'/img/close.svg'}/>Fechar
      </CloseButton>
    </Header>
    <Content>
      <h1>Obrigada.</h1>
      <h2>Em breve um de nossos consultores entrará em contato com você.</h2>
    </Content>
  </Container>
)

export default Thank
